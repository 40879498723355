import { Accordion, AccordionSummary, AccordionDetails, Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { cyan } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type SupportAccordionProps = {
  children: ReactNode;
  title: string;
};

export function SupportAccordion({ children, title }: SupportAccordionProps) {
  return (
    <Accordion
      elevation={0}
      sx={{
        border: 'none',
        backgroundColor: 'transparent',
        '&:before': {
          backgroundColor: 'transparent',
        },
      }}>
      <AccordionSummary
        sx={{ padding: 0 }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="crisis-support"
        id="crisis-support-headers">
        <Typography color={cyan[700]} variant="h4">
          {title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ padding: 0 }}>
        <Box>{children}</Box>
      </AccordionDetails>
    </Accordion>
  );
}
