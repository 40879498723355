import { Typography, useTheme } from '@mui/material';
import { cyan } from '@mui/material/colors';

type TitleProps = { content: string };

export function Title({ content }: TitleProps) {
  const theme = useTheme();

  return (
    <Typography my={theme.spacing(2)} fontWeight={700} color={cyan[800]} variant="h6">
      {content}
    </Typography>
  );
}
