import { Box, Typography, useTheme } from '@mui/material';
import { cyan } from '@mui/material/colors';
import { ExtLink } from 'components';

export type SupportContact = { title: string; description: string; link: string; phone?: string };

type SupportContactDetailsProps = { support: SupportContact };

export function SupportContactDetails({ support }: SupportContactDetailsProps) {
  const { link, title, description, phone } = support;
  const theme = useTheme();

  return (
    <Box>
      <Typography my={theme.spacing(1)} variant="h6">
        <ExtLink color={cyan[700]} href={link}>
          {title}
        </ExtLink>
      </Typography>
      <Typography>{description}</Typography>
      {phone && (
        <Typography fontWeight={600}>
          Phone: <ExtLink href={`tel:${phone}`}>{phone}</ExtLink>
        </Typography>
      )}
    </Box>
  );
}
