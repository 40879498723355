import { SitePage } from 'app/layout';
import { Resources } from 'app/resources';

export default function ResourcesPage() {
  return (
    <SitePage>
      <Resources />
    </SitePage>
  );
}
