import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography, useTheme } from '@mui/material';
import { cyan } from '@mui/material/colors';
import { ExtLink, Link } from 'components';
import { SupportAccordion } from './SupportAccordion';
import { SupportContact, SupportContactDetails } from './SupportContactDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Title } from './Title';

export function Resources() {
  const theme = useTheme();

  return (
    <Container>
      <Typography
        color={cyan[700]}
        variant="h4"
        align="left"
        sx={{
          marginTop: theme.spacing(4),
          marginBottom: theme.spacing(2),
        }}
      >
        Useful Resources for Parents, Carers & Young People
      </Typography>

      <Box py={2} mb={8}>
        <Typography paragraph>
          If you or your teenager want more information about mental health and related issues, or would like to speak
          to a mental health professional, please check out the following organisations and helplines. You’ll also find
          some other resources referred to in the PiP modules.
        </Typography>

        <Typography paragraph>
          If you need to contact the PiP-Ed team, see the <Link href="/contact">contact us</Link> page.
        </Typography>

        <SupportAccordion title="Crisis support helplines">
          <Title content="For immediate support, including if you or your teen feel unable to keep yourself, contact:" />
          {crisisSupport.map((contact, index) => (
            <SupportContactDetails support={contact} key={index} />
          ))}
        </SupportAccordion>

        <SupportAccordion title="Mental health support  for parents, carers and young people">
          <Title content="In-person psychological support" />
          {inPersonPsychologicalSupport.map((contact, index) => (
            <SupportContactDetails support={contact} key={index} />
          ))}
          <Title content="Telehealth psychological support and counselling" />
          If you want telehealth (online or phone) information and counselling for mental health issues, the following
          services may be suitable:
          {teleHealthPsychologicalSupport.map((contact, index) => (
            <SupportContactDetails support={contact} key={index} />
          ))}
          <Box>
            <Typography my={theme.spacing(1)} variant="h6">
              <ExtLink color={cyan[700]} href="https://au.reachout.com">
                ReachOut
              </ExtLink>{' '}
              &{' '}
              <ExtLink color={cyan[700]} href="https://parents.au.reachout.com">
                ReachOut Parents
              </ExtLink>
            </Typography>
            <Typography>
              Reachout provides self-help information, referral tools, and a peer-support program for young people and
              their parents.
            </Typography>
          </Box>
          <SupportContactDetails
            support={{
              link: 'https://someone.health/',
              title: 'Someone Health',
              description:
                'Someone Health provides Medicare bulk billing psychologist counselling Australia wide by video and telephone.',
            }}
          />
          <Box>
            <Typography my={theme.spacing(1)} variant="h6" color={cyan[700]}>
              Parent Line
            </Typography>
            <Typography>
              Parent line provides free and confidential ​​telephone counselling and support on parenting issues, for
              parents and carers of children from birth to 18 years old. See below for different state and territory
              contact details.
            </Typography>
          </Box>
          {parentLinkContacts.map(({ title, link, phone }, index) => (
            <Box key={index}>
              <Typography mt={theme.spacing(1)}>
                <ExtLink color={cyan[700]} href={link}>
                  {title}
                </ExtLink>
              </Typography>
              <Typography>
                Phone: <ExtLink href={`tel:${phone}`}>{phone}</ExtLink>
              </Typography>
            </Box>
          ))}
          <Box>
            <Typography mt={theme.spacing(1)}>
              <ExtLink color={cyan[700]} href="https://www.ngala.com.au/parenting-line/">
                WA Ngala Parenting Line
              </ExtLink>
            </Typography>
            <Typography>
              Phone Metro: <ExtLink href="tel:0893689368">08 9368 9368</ExtLink>
              <br />
              Phone Regional: <ExtLink href="tel:1800111546">1800 111 546</ExtLink>
            </Typography>
          </Box>
          <Title content="Evidence-based, online (self-guided) programs for mental health problems:" />
          {onlinePrograms.map((contact, index) => (
            <SupportContactDetails support={contact} key={index} />
          ))}
          <Title content="To explore other online resources and programs, check out:" />
          <SupportContactDetails
            support={{
              title: 'Head to Health',
              description:
                'Head to Health provides access to many of Australia’s trusted mental health and wellbeing organisations, information, online programs, and digital resources.',
              link: 'http://www.headtohealth.gov.au/',
            }}
          />
        </SupportAccordion>

        <SupportAccordion title="Additional resources about school refusal">
          <Title content="Books:" />
          <Box mb={theme.spacing(1)}>
            Kearney, C. A. (2021).{' '}
            <em>
              <b>Getting your child back to school: A parent's guide to solving school attendance problems.</b>
            </em>{' '}
            Oxford University Press.
          </Box>
          <Box>
            Albano, A. M., & Pepper, L. (2013).{' '}
            <em>
              <b>You and your anxious child: Free your child from fears and worries and create a joyful family life.</b>
            </em>{' '}
            Penguin Publishing Group.
          </Box>
          <Title content="Online resources:" />
          <Box mb={theme.spacing(1)}>
            <ExtLink href="https://www.parentingstrategies.net">Parent Strategies Guidelines</ExtLink> ‘Responding to
            school reluctance or refusal: Strategies for Parents’
          </Box>
          <Box>
            <ExtLink href="https://headspace.org.au/explore-topics/supporting-a-young-person/school-refusal/">
              headspace: Understanding School Refusal
            </ExtLink>
          </Box>
          <Title content="State-specific information or support for school attendance difficulties:" />
          <Typography>
            Please note that each state has different resources available for families dealing with school refusal. Some
            states have more support available than others. The below list includes some information or support that may
            be relevant for each state. Where there are no direct support services available, we have included links to
            government websites or policies about school engagement.
          </Typography>
          <Accordion
            elevation={0}
            sx={{ backgroundColor: 'transparent', '&:before': { backgroundColor: 'transparent' } }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ margin: 0 }} fontWeight={700} color={cyan[800]} variant="h6">
                Victoria
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <ul>
                <li>
                  <ExtLink href="https://www2.education.vic.gov.au/pal/navigator-program/policy">
                    The navigator program
                  </ExtLink>
                  . Navigator supports disengaged young people by working with the young person, their family and
                  support network to address issues underlying disengagement and help them re-engage with their
                  education. To be eligible, a young person (aged 12 to 17) must be enrolled in a Victorian education
                  setting (government or non-government) and have attended 30% or less of the previous school term.
                  Referrals can be made by anyone. Please note that the waitlist for this program is usually very long.
                </li>
                <li>
                  <ExtLink href="https://www2.education.vic.gov.au/pal/attendance/policy">
                    Victorian government school attendance policy
                  </ExtLink>
                  .
                </li>
                <li>
                  <ExtLink href="https://www2.education.vic.gov.au/pal/re-engagement-programs/policy">
                    School re-engagement programs policy
                  </ExtLink>
                  .
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            sx={{ backgroundColor: 'transparent', '&:before': { backgroundColor: 'transparent' } }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ margin: 0 }} fontWeight={700} color={cyan[800]} variant="h6">
                New South Wales
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <ul>
                <li>
                  <ExtLink href="https://www.health.nsw.gov.au/kidsfamilies/youth/Documents/forum-speaker-presentations/2017/webster-greenberg-sr-booklet.pdf">
                    School refusal: Everyday counts
                  </ExtLink>
                  . A resource booklet for schools and families about supporting young people with school refusal.
                </li>
                <li>
                  NSW government education website:{' '}
                  <ExtLink href="https://education.nsw.gov.au/parents-and-carers/going-to-school/preparing/starting-high-school/health-and-wellbeing-in-high-school/how-do-you-help-a-teen-who-refuses-to-go-to-school">
                    How do you help a teen who refuses to go to school?
                  </ExtLink>
                </li>
                <li>
                  <ExtLink href="https://education.nsw.gov.au/student-wellbeing/whole-school-approach/inclusive--engaging-and-respectful-schools#Why1">
                    Inclusive, Engaging and Respectful Schools Policy
                  </ExtLink>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            sx={{ backgroundColor: 'transparent', '&:before': { backgroundColor: 'transparent' } }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ margin: 0 }} fontWeight={700} color={cyan[800]} variant="h6">
                Australian Capital Territory
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <ul>
                <li>
                  <ExtLink href="https://www.education.act.gov.au/support-for-our-students/positive-behaviour-mental-health-and-wellbeing-approaches-in-our-schools/supporting-students-engagement-in-high-school">
                    ACT Government: Supporting Students Engagement in High School.
                  </ExtLink>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            sx={{ backgroundColor: 'transparent', '&:before': { backgroundColor: 'transparent' } }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ margin: 0 }} fontWeight={700} color={cyan[800]} variant="h6">
                Queensland
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <ul>
                <li>
                  <ExtLink href="https://www.rap.qut.edu.au/">The Resourceful Adolescent Program (RAP)</ExtLink>.
                  Building resilience and promoting positive mental health in teenagers, improving family relationships,
                  promoting school connectedness.
                </li>
                <li>
                  <ExtLink href="https://education.qld.gov.au/initiatives-and-strategies/initiatives/every-day-counts">
                    The Every Day Counts program
                  </ExtLink>
                  . Every day counts is a state wide initiative that aims to assist in improving student attendance at
                  school through a shared commitment by students, parents, caregivers, schools and the community. This
                  website includes information for schools, families, and students themselves.
                </li>
                <li>
                  <ExtLink href="https://www.sparktheirfuture.qld.edu.au/school-refusal-child-wont-go/">
                    QLD government ‘spark their future’
                  </ExtLink>
                  . This page includes some information and links to other resources about school refusal.
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            sx={{ backgroundColor: 'transparent', '&:before': { backgroundColor: 'transparent' } }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ margin: 0 }} fontWeight={700} color={cyan[800]} variant="h6">
                Tasmania
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <ul>
                <li>
                  <ExtLink href="https://www.education.tas.gov.au/about-us/projects/child-student-wellbeing/key-projects/#:~:text=Back%20on%20Track%20is%20an,young%20person's%20barriers%20to%20engagement">
                    The back on track program
                  </ExtLink>{' '}
                  is an outreach approach to support young people who have not successfully transitioned from Year 10 to
                  Years 11 and 12.
                </li>
                <li>
                  Uniting:{' '}
                  <ExtLink href="https://www.unitingvictas.org.au/services/family-services/youth-family-services/school-engagement/">
                    Family services for school engagement
                  </ExtLink>
                  . The school engagement programs work with students who are at risk of disengaging from school and
                  their families. These programs support the wellbeing of children and their families and help to build
                  on strengths, increase school attendance, improve school connectedness and get more involved in their
                  community.{' '}
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            sx={{ backgroundColor: 'transparent', '&:before': { backgroundColor: 'transparent' } }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ margin: 0 }} fontWeight={700} color={cyan[800]} variant="h6">
                Western Australia
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              We are not aware of any current government programs for families of young people with school refusal. The
              following information is available, however this is not directed at families.
              <ul>
                <li>
                  <ExtLink href="https://www.education.wa.edu.au/-/engagement-centres">
                    Student engagement centres
                  </ExtLink>
                  .
                </li>
                <li>
                  <ExtLink href="https://www.ccyp.wa.gov.au/our-work/projects/student-wellbeing-in-schools/">
                    Western Australia Commissioner for Children and Young People
                  </ExtLink>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            sx={{ backgroundColor: 'transparent', '&:before': { backgroundColor: 'transparent' } }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ margin: 0 }} fontWeight={700} color={cyan[800]} variant="h6">
                South Australia
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <ul>
                <li>
                  South Australia Department for Education web page{' '}
                  <ExtLink href="https://www.education.sa.gov.au/parents-and-families/safety-and-wellbeing/attendance-school-or-preschool/when-your-child-needs-support-get-school-or-preschool">
                    ‘When your child needs support to get to school or preschool’
                  </ExtLink>
                  .
                </li>
                <li>
                  Information on{' '}
                  <ExtLink href="https://www.education.sa.gov.au/parents-and-families/safety-and-wellbeing/attendance-school-or-preschool/when-your-child-needs-support-get-school-or-preschool">
                    Support Services
                  </ExtLink>{' '}
                  offered by the Department of Education.
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            sx={{ backgroundColor: 'transparent', '&:before': { backgroundColor: 'transparent' } }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ margin: 0 }} fontWeight={700} color={cyan[800]} variant="h6">
                Northern Territory
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              We are not aware of any current government programs for families of young people with school refusal.
              Relevant government policies (not written for families):
              <ul>
                <li>
                  <ExtLink href="https://nt.gov.au/learning/primary-and-secondary-students/your-childs-absence-from-school">
                    NT Government web page on school attendance
                  </ExtLink>
                </li>
                <li>
                  <ExtLink href="https://education.nt.gov.au/statistics-research-and-strategies/education-engagement-strategy">
                    NT Education Engagement Strategy 2022-2031
                  </ExtLink>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </SupportAccordion>

        <SupportAccordion title="Additional mental health resources just for young people">
          <Typography my={theme.spacing(2)} fontWeight={700} color={cyan[800]} variant="h6">
            To learn more about mental health issues:
          </Typography>
          {mentalHealthInformationForYoungPeople.map((contact, index) => (
            <SupportContactDetails support={contact} key={index} />
          ))}
          <Typography my={theme.spacing(2)} fontWeight={700} color={cyan[800]} variant="h6">
            Online and phone counselling:
          </Typography>
          {onlineAndPhoneCounsellingForYoungPeople.map((contact, index) => (
            <SupportContactDetails support={contact} key={index} />
          ))}
          <Title content="Support for issues relating to gender, identity and sexuality:" />
          {supportRelatingToGenderIdentitySexualityForYoungPeople.map((contact, index) => (
            <SupportContactDetails support={contact} key={index} />
          ))}
        </SupportAccordion>

        <SupportAccordion title="Legal, financial and resources for family violence">
          <Typography>
            The services listed below provide information and referral pathways for families and individuals struggling
            with family violence, accommodation, legal and financial difficulties.
          </Typography>
          <Title content="Family violence and emergency accommodation" />
          <SupportContactDetails
            support={{
              title: '1800 Respect',
              link: 'https://www.1800respect.org.au/',
              description:
                'Provides 24/7 telephone and online counselling support as well as access to information and services for people impacted by sexual assault, domestic or family violence and abuse.',
            }}
          />
          <Title content="Fincancial Support" />
          <ul>
            <li>
              <ExtLink href="https://www.centrelink.gov.au/custsite_pfe/pymtfinderest/paymentFinderEstimatorPage.jsf?wec-appid=pymtfinderest&wec-locale=en_US#stay">
                Centrelink’s Parent Payment Finder
              </ExtLink>{' '}
              can help you find Australian government payments and services you may be eligible for.
            </li>
            <li>
              <ExtLink href="https://www.astartinlife.org.au/">A Start in Life</ExtLink> is an Australian charity that
              provides a range of assistance, including financial assistance, to students from kindergarten through to
              tertiary studies.
            </li>
            <li>
              <ExtLink href="https://www.thesmithfamily.com.au/programs/learning-for-life">
                The Smith Family’s Learning for Life program
              </ExtLink>{' '}
              provides financial support, guidance and mentoring for disadvantaged students. The Smith Family works with
              a select number of schools and may require referral from the school principal.
            </li>
            <li>
              There are other services available in each state and local community. We suggest asking your child’s
              school about other services you may be eligible for.
            </li>
          </ul>
          <Title content="Legal support" />
          <Typography my={theme.spacing(1)} variant="h6">
            <ExtLink color={cyan[700]} href="https://www.ag.gov.au/legal-system/legal-assistance-services">
              Australian Legal Assistance Services
            </ExtLink>
          </Typography>
          <Typography mt={theme.spacing(1)}>
            Legal Aid provides free legal services, including information, legal advice, and representation in courts
            and tribunals. Each state and territory has its own Legal Aid service provider:
          </Typography>
          {stateLegalAid.map((contact, index) => (
            <Box key={index}>
              <Typography>{contact.state}</Typography>
              <Typography>
                <ExtLink href={contact.link}>{contact.description}</ExtLink>
              </Typography>
            </Box>
          ))}
          <SupportContactDetails
            support={{
              title: 'Family Advocacy and Support Service',
              description:
                'The Family Advocacy and Support Service combines free legal advice and support at court for people in Australia affected by domestic and family violence. Each Australian State and Territory has a Family Advocacy and Support Service. Refer to the website for state/territory specific contact information.',
              link: 'https://familyviolencelaw.gov.au/fass/',
            }}
          />
          <SupportContactDetails
            support={{
              title: 'Youth Law Australia',
              description:
                'Free, confidential legal information and advice for young people under 25. Provides information specific to all Australian states and territories.',
              link: 'https://yla.org.au',
            }}
          />
        </SupportAccordion>
      </Box>
    </Container>
  );
}

const crisisSupport: SupportContact[] = [
  {
    title: 'Lifeline',
    description: 'Lifeline provides access to crisis support, suicide prevention and mental health support services.',
    link: 'https://www.lifeline.org.au/',
    phone: '13 11 14',
  },
  {
    title: 'Suicide Call Back Service',
    description:
      'Suicide Call Back Service provides 24/7 telephone and online counselling to people affected by suicide.',
    link: 'https://www.suicidecallbackservice.org.au/',
    phone: '1300 659 467',
  },
  {
    title: 'Kids Helpline',
    description:
      'A free, private, telephone and online counselling service specifically for young people aged 5 to 25 years.',
    link: 'https://kidshelpline.com.au/',
    phone: '1800 55 1800',
  },
  {
    title: 'In an emergency, dial 000',
    link: 'https://www.triplezero.gov.au/',
    description: 'or visit your nearest hospital emergency department.',
  },
];

const inPersonPsychologicalSupport: SupportContact[] = [
  {
    title: 'Medicare-subsidised psychology sessions with a mental health treatment plan',
    link: 'https://www.healthdirect.gov.au/mental-health-treatment-plan',
    description:
      'A Mental Health Treatment Plan (MHTP) is a plan provided by a general practitioner (GP) to support someone with mental health issues. A MHTP can include referral to a mental health professional, including registered psychologists, psychiatrists, social workers and occupational therapists. If you have a MHTP, Medicare will pay some or all of the cost of a limited number of sessions each year. The out of pocket cost varies for different professionals or clinics.',
  },
  {
    title: 'Find a Psychologist',
    link: 'https://psychology.org.au/find-a-psychologist',
    description:
      'If you or your teenager would like to find a registered psychologist in your area, the Australian Psychological Society Find a Psychologist service can assist with this.',
  },
];

const teleHealthPsychologicalSupport: SupportContact[] = [
  {
    title: 'Beyond Blue',
    link: 'http://www.beyondblue.org.au/getsupport',
    description:
      'Learn more about depression and anxiety, or talk it through with support services at Beyond Blue. Email or chat online.',
    phone: '1300 224 636',
  },
  {
    title: 'eheadspace',
    link: 'https://headspace.org.au/online-and-phone-support/',
    description:
      'eheadspace is a confidential, free and secure space where young people aged 12 to 25 or their family can chat, email or speak on the phone with a qualified youth mental health professional.',
    phone: '1800 650 890',
  },
];

const parentLinkContacts = [
  { title: 'ACT Parentline', phone: '02 6287 3833', link: 'http://parentlineact.org.au' },
  { title: 'NT and QLD Parentline', phone: '1300 30 1300', link: 'https://parentline.com.au/' },
  { title: 'NSW Parent Line', phone: '1300 1300 52', link: 'https://www.parentline.org.au' },
  { title: 'SA Parent Helpline', phone: '1300 364 100', link: 'https://www.cafhs.sa.gov.au/services/parent-helpline' },
  {
    title: 'TAS Parent Line',
    phone: '1300 808 178',
    link: 'https://www.health.tas.gov.au/health-topics/childrens-health/child-health-and-parenting-service-chaps/parent-line',
  },
  { title: 'VIC Parentline', phone: '13 22 89', link: 'https://services.dffh.vic.gov.au/parentline' },
];

const onlinePrograms: SupportContact[] = [
  {
    title: 'Moodgym',
    description:
      'Moodgym is an interactive program based on Cognitive Behaviour Therapy and Interpersonal Therapy. It can help you to identify the mental health difficulties and to learn skills to cope with your emotions.',
    link: 'https://moodgym.com.au/',
  },
  {
    title: 'This Way Up',
    description:
      'This Way Up provides a suite of self-paced online programs for a range of mental health problems. The programs can teach you strategies to help improve the way you feel.',
    link: 'https://thiswayup.org.au/',
  },
  {
    title: 'Mindspot',
    description:
      'Mindspot provides access to free online and telephone mental health assessments, treatment programs, and a resource library for a range of mental health problems.',
    link: 'https://mindspot.org.au/',
    phone: '1800614434',
  },
];

const mentalHealthInformationForYoungPeople: SupportContact[] = [
  {
    title: 'Youth Beyond Blue',
    link: 'http://www.beyondblue.org.au/who-does-it-affect/young-people',
    description:
      'Youth Beyond Blue’s website is for young people aged 12 to 15. If you need to talk to someone, find more information, or find out where you can go to see someone contact the beyondblue support service.',
    phone: '1300 22 4636',
  },
  {
    title: 'headspace',
    link: 'http://www.headspace.org.au',
    description:
      'Headspace helps young people aged 12 to 25 who are going through a tough time, providing support for problems like depression, anxiety, bullying and body image.',
    phone: '1800 650 890',
  },
];

const onlineAndPhoneCounsellingForYoungPeople: SupportContact[] = [
  {
    title: 'eheadspace',
    link: 'https://headspace.org.au/online-and-phone-support/',
    description:
      'eheadspace is a confidential, free and secure space where young people aged 12 to 25 or their family can chat, email or speak on the phone with a qualified youth mental health professional.',
  },
  {
    title: 'Kids Helpline',
    link: 'https://kidshelpline.com.au/',
    description:
      'A free, private and confidential, telephone and online counselling service specifically for young people aged between 5 and 25.',
    phone: '1800 55 1800',
  },
];

const supportRelatingToGenderIdentitySexualityForYoungPeople: SupportContact[] = [
  {
    title: 'QLife',
    link: 'https://qlife.org.au',
    description:
      'Australia-wide, anonymous, peer support and referral for people wanting to talk about issues relating to sexuality, identity, gender, bodies, feelings, or relationships. Phone and web-chat available.',
  },
  {
    title: 'qheadspace',
    link: 'http://www.headspace.org.au/online-and-phone-support/spaces/community/372936',
    description: 'A peer led online chat for LGBTQIA+ young people in Australia.',
  },
  {
    title: 'Reachout Peer Chat',
    link: 'https://au.reachout.com/peerchattps://au.reachout.com',
    description:
      'If you have mental health challenges or something on your mind that you would like to chat about, you can book a free and confidential text-based chat with a peer worker.',
  },
];

const stateLegalAid = [
  { state: 'ACT', link: 'http://www.legalaidact.org.au/', description: 'Legal Aid Australian Capital Territory' },
  { state: 'NSW', link: 'http://www.legalaid.nsw.gov.au/', description: 'Legal Aid New South Wales' },
  { state: 'NT', link: 'https://www.legalaid.nt.gov.au/', description: 'Northern Territory Legal Aid Commission' },
  { state: 'QLD', link: 'http://www.legalaid.qld.gov.au/Home', description: 'Legal Aid Queensland' },
  { state: 'SA', link: 'http://www.lsc.sa.gov.au/', description: 'Legal Services Commission of South Australia' },
  { state: 'TAS', link: 'http://www.legalaid.tas.gov.au/', description: 'Legal Aid Commission of Tasmania' },
  { state: 'VIC', link: 'http://www.legalaid.vic.gov.au/', description: 'Victoria Legal Aid' },
  { state: 'WA', link: 'https://www.legalaid.wa.gov.au/', description: 'Legal Aid Western Australia' },
];
